.home {
  &-content {
    z-index: 100;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  &-text {
    position: absolute;
    top: 40vh;
    text-align: center;
  }
}

@media (max-width: 880px) {
  .home {
    &-img {
      height: 100vh;
      object-fit: cover;
    }

    &-text {
      top: 30vh;
      color: var(--beige);
    }
  }
}

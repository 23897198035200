.footer {
  &-container {
    display: flex;
    justify-content: center;
    background-color: var(--dark);
    padding: 15px 0;
    flex: 0 1 auto;
    text-align: center;

    & a {
      margin: 5px 15px;
      color: var(--light);
      font-size: 0.8rem;
    }

    & a:hover {
      transition: 0.5s ease-out;
      text-decoration: underline;
    }
  }
  &-link {
    margin: 0 10;
  }
}

@media screen and (max-width: 820px) {
  .footer {
    &__content {
      flex-direction: column;
      max-width: 90%;
    }

    &__links {
      align-items: center;
    }
  }
}

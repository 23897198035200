.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-img {
    width: 400px;
  }

  &-btn {
    margin-bottom: 30px;
  }
}

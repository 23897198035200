.about {
  &-map {
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    &-info {
      width: 20%;
      background-color: var(--dark);
      color: var(--light);
    }
    &-text {
      text-align: center;
      margin: 50% 5%;
      line-height: 2rem;
      display: block;
      color: var(--light);
    }
  }

  &-articles {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }
  &-article {
    padding: 0 40px 30px;
    width: 48%;
    text-align: center;

    border: 2px dashed var(--green);
    border-radius: 20px;
    background-color: var(--lightbeige);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

    &-img {
      width: 60%;
    }
    &-txt {
      margin: 10px auto 0;
      text-align: justify;
      text-justify: auto;
    }
  }
}

// for the map on the about page
.leaflet-container {
  height: 50vh;
  width: 80%;
}

@media (max-width: 880px) {
  .about {
    &-articles {
      flex-direction: column;
      align-items: center;
    }
    &-article {
      margin-bottom: 30px;
      width: 80%;
    }

    &-map {
      flex-direction: column;
      &-info {
        width: 100%;
      }
      &-text {
        margin: 5% auto;
      }
    }
  }
  .leaflet-container {
    width: 100%;
  }
}

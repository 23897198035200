:root {
  --text: #000000;
  --background: #ffffff;

  --dark: #001f36;
  --light: #fdfeee;
  --lightbeige: #fdf9f2;
  --beige: #fef4e4;
  --orange: #f6912b;
  --red: #f30c3f;
  --blue: #42b9e8;
  --green: #3bafa3;
  --darkgreen: #01626f;
  --darkblue: #0180b2;
  // --color1: '';
  // --color2: '';
  // --color3: '';
  // --color4: '';

  display: flex;
  flex-flow: column;
}

.App {
  display: flex;
  flex-flow: column;
  height: 100vh;

  /* In order for the footer to always appear down even if the page is not 100vh:
  navbar : flex: 0 1 auto; (grow/shrink)
  page: 1 1 auto (shrink/shrink)
  footer : flex: 0 1 auto; (grow/shrink)
  */
}

body {
  font-family: 'Outfit', sans-serif;
  color: var(--dark);
  font-size: 1.1em;
}

a {
  text-decoration: none;
  color: var(--dark);
}

.page {
  max-width: 80%;
  padding: 120px 0 0;
  flex: 1 1 auto;
  margin: 0 auto;
}

h1 {
  font-weight: 700;
  font-size: 2.5rem;
}

h2 {
  font-weight: 600;
  font-size: 1.8rem;
}

h3 {
  font-weight: 500;
  font-size: 1.4rem;
}

br {
  color: white;
}

h1,
h2,
h3 {
  margin: 20px 0;
  text-align: center;
}

li {
  margin: 5px 0;
}

.txt {
  margin: 15px 0;
}

b,
.bold {
  font-weight: 500;
}

strong {
  font-size: larger;
}

.spacey {
  margin: 15px;
}

.center {
  text-align: center;
}

.hidden {
  display: none;
}
.underlined {
  text-decoration: underline;
}

hr {
  width: 40%;
  margin: 30px auto;
  border: 1px dashed var(--dark);
}

// FADE IN ANIMATION

.fade-in-from-bottom-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-from-bottom-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-from-left-section {
  opacity: 0;
  transform: translateX(-20vw);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-from-left-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-from-right-section {
  opacity: 0;
  transform: translateX(20vw);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-from-right-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-from-top-section {
  opacity: 0;
  transform: translateY(-20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-from-top-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.btn {
  border: none;
  background-color: var(--dark);
  color: var(--light);
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease;
  }
}

@media screen and (max-width: 1250px) {
  .heading {
    font-size: 1.7em;
  }

  .heading--medium {
    font-size: 1.8em;
  }

  .heading--small {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 1060px) {
  .btn {
    &--large {
      padding: 8px 25px;
      font-size: 1.1rem;
    }

    &--medium {
      padding: 6px 20px;
      font-size: 1rem;
    }

    &--small {
      padding: 4px 18px;
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: 880px) {
  .page {
    padding-top: 110px;
  }
}

@media screen and (max-width: 768px) {
  .page {
    text-align: center;
    margin-bottom: 80px;
    max-width: 100%;
    margin: 0;
  }
  .heading {
    font-size: 1.5em;
  }

  .text {
    font-size: 1.1rem;
    margin: 15px 30px;
  }
}

@media screen and (max-width: 430px) {
  .btn {
    &--large {
      padding: 6px 20px;
      font-size: 1rem;
    }
  }
  p {
    line-height: 1.5rem;
  }
}

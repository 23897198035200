.answer {
  text-align: center;

  &-txt {
    margin: 15px 0;
  }

  &-warning {
    color: darkred;
    font-weight: 500;
  }

  &-undertitle {
    font-size: 1.2em;
    font-weight: 500;
    margin: 15px 0;
  }

  &-btn {
    margin: 15px 10px;
    width: 125px;
    &:hover {
      background-color: var(--dark);
    }
    &-confirm {
      background-color: green;
    }
    &-cancel {
      background-color: darkred;
    }
  }
}

.bkg {
  &-list {
    list-style: none;
  }

  &-item {
    margin: 5px 0;
  }
}

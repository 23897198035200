.form {
  background-color: var(--dark);
  border-radius: 20px;
  color: var(--light);
  padding: 15px 30px;
  text-align: center;
  margin: 10px auto 30px;
  width: 70vw;

  &-input {
    margin: 15px;
    padding: 10px;
    background-color: var(--light);
    border-radius: 15px;
    color: var(--dark);
    width: 50%;
    text-align: left;
  }

  &-textarea {
    height: 200px;
  }

  &-date {
    padding: 10px;
    background-color: var(--light);
    border-radius: 15px;
    color: var(--dark);
    width: 80%;
  }

  &-inputs {
    display: flex;
    flex-direction: row;
    margin: 10px;
  }

  &-text {
    margin-top: 20px;
  }

  &-addpax {
    margin: 15px;
    padding: 10px;
  }

  &-btn-pax {
    border: 1px var(--light) solid;
    padding: 10px;
    border-radius: 15%;
    cursor: pointer;
    margin: 5px;
    &:hover {
      color: var(--dark);
      background-color: var(--light);
    }
  }

  &-btn-submit {
    border: 1px var(--light) solid;
    margin: 20px;
    width: 250px;
    &:hover {
      background-color: var(--green);
      border: none;
    }
  }

  &-block {
    border-bottom: 1px solid var(--light); /* or any other color */
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 880px) {
  .form {
    margin: 10px 5% 30px;
    width: 90vw;

    &-input {
      width: 80%;
    }
  }
}

.res {
  &-tiles {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    margin: 30px 0;
  }

  &-text {
    width: 100px;
    text-align: center;
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;

    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-img {
    text-align: center;
    margin: 5px;
  }

  &-checklist {
    background-color: var(--darkgreen);
    color: var(--light);
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 10px;
    &-item {
      display: flex;
      flex-direction: row;
      margin: 30px;
    }
    &-txt {
      margin-left: 5px;
      text-align: left;
    }
  }
}

@media (max-width: 880px) {
  .res {
    &-checklist {
      margin: 0 5% 10%;
    }
  }
}
